import React, { useState, useEffect } from 'react';
import './App.css';
import { toHex } from './utills';
import { useAppWeb3Provider } from './contexts/Web3Context';

const App = () => {
    const appWeb3 = useAppWeb3Provider();
    const [txDone, setTxDone] = useState(false);
    const [txinfo, setTxInfo] = useState('');
    const [mintActive, setMintActive] = useState(false);
    const [mintQty, setMintQTY] = useState(0);
    const [proofhash, setProofhash] = useState([]);

    //VARS
    const [totalMinted, setTotalMinted] = useState(0);
    const [price, setPrice] = useState(0);
    const [userMinted, setUserMinted] = useState(0);
    const [allowedMint, setAllowedMinted] = useState(0);
    const [mintBtn, setMintBtn] = useState('Mint Now');


    const whitelist = [
        "0xb85F8664A27580554C625adB6E8A6FDeE68Ba28B",
        "0xbcD70b7436A0EDF418A0E350610F25fe12d2223C",
        "0x2E940afb4035E846Bc09c387578F2aED1C3c6d0D",
        "0xAe6DA0d88B83768bF53B403E90499276FFF2199e",
        "0x14FB29c4b217aBf5d961f36FE8441dE7496839cA",
        "0xDf3759cc2277aDcDB0a97b8AC1469a6EddBC6A8d"
    ];

    function getProof(_ADDRESS) {
        const leaves = whitelist.map(x => keccak256(x));
        const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
        const leaf = keccak256(_ADDRESS);
        const hexProof = tree.getHexProof(leaf);
        return hexProof;
    }

    function checkWallet(_WALLET) {
        const leaves = whitelist.map(x => keccak256(x));
        const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
        const root = tree.getRoot().toString('hex');
        const leaf = keccak256(_WALLET);
        const proof = tree.getProof(leaf);
        return tree.verify(proof, leaf, root);
    }

    useEffect(async () => {
        if (appWeb3.methodsMint && appWeb3.account) {
            await appWeb3.methodsMint.whitelistSale().call(async function (error, result) {
                if (result === true) {
                    setMintActive(true);
                    setProofhash(getProof(appWeb3.account));
                    if (getProof(appWeb3.account).length > 0) {
                        setMintBtn('Mint Now');
                    } else {
                        setMintBtn('Wallet not WL');
                    }
                }
            });
            await appWeb3.methodsMint.publicSale().call(async function (error, result) {
                if (result === true) {
                    setMintActive(true);
                }
            });
            await appWeb3.methodsMint.totalSupply().call(async function (error, result) {
                if (result) {
                    setTotalMinted(result);
                }
            });
            await appWeb3.methodsMint.price().call(async function (error, result) {
                if (result) {
                    setPrice(appWeb3.library.utils.fromWei(result, 'ether'));
                }
            });
            await appWeb3.methodsMint.numberMinted(appWeb3.account).call(async function (error, result) {
                if (result) {
                    console.log("You've minted", result);
                    setUserMinted(result);
                }
            });
            await appWeb3.methodsMint.maxPerAddress().call(async function (error, result) {
                if (result) {
                    console.log("Max Per Address", result);
                    setAllowedMinted(result);
                }
            });
            await checkWallet(appWeb3.account);
            setInterval(
                function () {
                    appWeb3.methodsMint.totalSupply().call(async function (error, result) {
                        if (result) {
                            setTotalMinted(result);
                        }
                    });
                },
                8000
            );
        }
    }, [appWeb3.account, txDone]);

    const handleMint = async () => {
        console.log('PRROF HASH', proofhash, mintBtn);
        if (mintQty > 0 && mintBtn == 'Mint Now') {
            setTxInfo('Approve the Transaction in wallet');
            appWeb3.methodsMint.mint(mintQty, proofhash).send({
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
                from: appWeb3.account,
                value: appWeb3.library.utils.toWei((price * mintQty).toString())
            }, async function (error, proccessed) {
                if (error) {
                    setTxInfo('Transaction Rejected in wallet');
                }
                if (proccessed) {
                    setTxInfo('Transaction accepted waiting for response from blockchain...');
                }
            }).on('transactionHash', function (hash) {
            }).on('confirmation', function (confirmationNumber, receipt) {
            }).on('receipt', async function (receipt) {
                setTxInfo('You have successfully minted!');
                setTxDone(true);
                setMintQTY(0);
            });
        } else if (userMinted >= allowedMint) {
            setTxInfo('Maximum Limit Reached');
        } else if (mintBtn == 'Wallet not WL') {

        } else {

        }
    }

    const dec = () => {
        if (mintQty > 0) {
            setMintQTY(parseInt(mintQty) - 1);
        }
    }

    const inc = () => {
        if (mintQty < allowedMint - userMinted) {
            setMintQTY(parseInt(mintQty) + 1);
        }
    }

    return (
        <>
            <div className="section section-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-6">
                            <div className="logo m-auto">
                                <img src="/assets/img/logo.png" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-6">
                            <div className="signup-wrap">
                                <div className="signup-sec p-3 mb-2">
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <p className="m-0">NFTs Minted</p>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <p className="m-0 text-end"><span className="nonce">{totalMinted}</span> / <span
                                                className="maxTotalSupply">1997</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="signup-sec p-3">
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <p className="m-0">Price</p>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <p className="m-0 text-end"><span className="pricePerToken">{price}</span> Ξ</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="signup-form mt-4">
                                    <div className="input-wrap">
                                        <div className="update-btn decrement">
                                            <button onClick={dec}><i className="fas fa-minus"></i></button>
                                        </div>
                                        <div className="qty">{mintQty}</div>
                                        <div className="update-btn increment">
                                            <button onClick={inc}><i className="fas fa-plus"></i></button>
                                        </div>
                                    </div>
                                    {
                                        !appWeb3.account ? (
                                            <button id="connect_wallet" className="btn text-uppercase mt-4" onClick={async => appWeb3.connectWallet()}>
                                                Connect walet
                                            </button>
                                        ) : appWeb3.chainId != toHex(appWeb3.allowedChain) ? (
                                            <button id="connect_wallet" className="btn text-uppercase mt-4" onClick={async => appWeb3.switchNetwork()}>
                                                Change Network
                                            </button>
                                        ) : mintActive == true ? (
                                            <button id="connect_wallet" className="btn text-uppercase mt-4" onClick={async => handleMint()}>
                                                {mintBtn}
                                            </button>
                                        ) : (
                                            <button id="connect_wallet" className="btn text-uppercase mt-4" disabled>
                                                Mint not yet active
                                            </button>
                                        )
                                    }
                                </div>
                                <div className="signup-form mt-4">
                                    <p className="notice">{txinfo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default App;